
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Compnents/Pages/Home';

import Footer from './Compnents/Pages/Footer';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './Compnents/Pages/About';
import Book from './Compnents/Pages/Book';
import Navb from './Compnents/Pages/Navb';
import ScrollToTop from './ScrollToTop';
import Wats from './Compnents/Pages/Wats';
import Gelery from './Compnents/Pages/Gelery';
import Faq from './Compnents/Pages/Faq';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
      <Navb/>
      
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/book" element={<Book />}></Route>
        <Route path="/gelery" element={<Gelery />}></Route>
        <Route path="/faq" element={<Faq />}></Route>




        
      </Routes>
      <Wats/>
      <Footer/>
    </BrowserRouter>
      
      <div>
     
      </div>
    </div>
  );
}

export default App;

import AOS from 'aos'
import 'aos/dist/aos.css'
import { React, useEffect } from "react"
import "../Css/Gelery.css"
const Gelery = () => {
    useEffect(()=>{
        AOS.init({duration:2000})
      },[])
    
  return (
    <div className='maincont'>
         <div data-aos="fade-up" className='bgimg'>
        <h1 >Image Gallery </h1>
        <p> Some beautiful pictures of Kashmir</p>
      </div>
    <div className='main2'>
        <div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685512414/snow-3971863_640_yjfcno.jpg' alt=''/>
        </div>
        <div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685512396/srinagar-4099009_640_lqskmi.jpg' alt=''/>
        </div>
        <div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685512367/sky-4917936_640_s5kl9w.jpg' alt=''/>
        </div><div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685512355/zal-zal-lake-azad-kashmir-4405230_640_rkcrnp.jpg' alt=''/>
        </div><div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685512338/landscape-2176348_640_zxfnau.jpg' alt=''/>
        </div><div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685512331/srinagar-3609032_640_fl5owk.jpg' alt=''/>
        </div><div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685512324/landscape-2149844_1280_uvzblf.jpg' alt=''/>
        </div><div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685512314/statue-2691173_1280_zdfg3w.jpg' alt=''/>
        </div><div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685513875/WhatsApp_Image_2023-05-28_at_04.43.28_l5zhw8.jpg' alt=''/>
        </div><div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685514547/lake-7039109_640_gvb4rv.jpg' alt=''/>
        </div>
        <div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685514537/mountains-5969476_640_xvr8wp.jpg' alt=''/>
        </div><div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685514501/boat-6674889_640_t8z2xh.jpg' alt=''/>
        </div><div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685514478/landscape-2149844_1280_pjiwty.jpg' alt=''/>
        </div>
        <div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685547493/lake-6123622_640_l1kdat.jpg' alt=''/>
        </div>
        <div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685547479/ladakh-16909_640_h8zhjy.jpg' alt=''/>
        </div> <div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685547431/rider-1646754_640_welrrq.jpg' alt=''/>
        </div> <div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685547414/nubra-232728_640_halv4j.jpg' alt=''/>

        </div>
        <div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685547403/ladakh-2300904_640_annwnz.jpg' alt=''/>
        </div>
        <div data-aos="fade-up" className='mainimage'>
            <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685547401/ladakh-2818861_1280_ebiap9.jpg' alt=''/>
        </div>
    </div>
    </div>
  )
}

export default Gelery
import AOS from 'aos'
import 'aos/dist/aos.css'
import { React, useEffect } from "react"
import "../Css/about.css"
const About = () => {
  useEffect(()=>{
    AOS.init({duration:2000})
  },[])
  return (
    <div>
      <div data-aos="fade-up" className='faqq1'>
        <h1 >Welcome to </h1>
        <p> Shaelsan Tour & Travels</p>
      </div>
    <div className='mainabout'>


      <h2 data-aos="fade-up">Welcome to Shaelsan Travel and Tourism!</h2>
      <p1 data-aos="fade-up" >At Shaelsan Travel and Tourism, we believe that every journey is an opportunity to explore, discover, and create lifelong memories. We are a premier travel business specializing in providing unforgettable travel experiences in the captivating region of Kashmir.<br/>

Our company is dedicated to curating unique and authentic travel itineraries that showcase the mesmerizing beauty and cultural richness of Kashmir. Whether you are a solo traveler, a couple seeking a romantic getaway, a family yearning for an adventure, or a group of friends eager to explore, we have the perfect package tailored to suit your preferences and interests.<br/><br/>

What sets us apart is our commitment to delivering exceptional service and ensuring that your journey with us is nothing short of extraordinary. Our team of experienced travel experts are passionate about Kashmir and possess extensive knowledge of the region, allowing us to craft meticulously planned itineraries that encompass the must-visit destinations and hidden gems of the area.<br/><br/>

We understand that every traveler has unique aspirations and desires when it comes to exploring Kashmir. That's why we offer a wide range of travel options, including guided tours, adventure expeditions, cultural immersions, spiritual retreats, and luxurious escapes. Whether you dream of cruising on Dal Lake, embarking on a thrilling trek in the Himalayas, exploring the historical Mughal Gardens, or experiencing the warm hospitality of local communities, we have the perfect itinerary to fulfill your travel dreams.<br/><br/>

At Shaelsan Travel and Tourism, we prioritize your safety and comfort above all else. We partner with trusted accommodation providers, reliable transportation services, and knowledgeable local guides who are dedicated to ensuring your well-being throughout your journey. Our attention to detail, personalized service, and commitment to excellence have earned us a reputation as a leading travel company in Kashmir.<br/><br/>

We firmly believe in sustainable and responsible tourism practices. We strive to minimize the impact of our operations on the environment and actively support local communities, aiming to promote cultural preservation, economic growth, and environmental conservation.<br/>

Let us be your gateway to the enchanting paradise of Kashmir. Join us on a remarkable journey that will leave you with memories to cherish for a lifetime. Choose Shaelsan Travel and Tourism for an immersive, unforgettable travel experience in the mesmerizing realm of Kashmir.
<br/><br/>

<b>Contact us today and let us make your travel dreams in Kashmir come true!</b></p1>
    </div>
    </div>
  )
}

export default About
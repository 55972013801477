
import "../Css/Faq.css"
import AOS from 'aos'
import 'aos/dist/aos.css'
import { React, useEffect } from "react"
const Faq = () => {
    useEffect(()=>{
        AOS.init({duration:2000})
      },[])
  return (
    <div className='mainfq'>
        <div data-aos="fade-up" className='faq1'>
        <h1 > FAQs </h1>
        <p> frequently asked questions</p>
      </div>
      <div className='faqtxt'>
        <h3 data-aos="fade-up"> Q1 :  What are some must-visit destinations in Kashmir?</h3>
        <p data-aos="fade-up">Kashmir is blessed with stunning natural landscapes and rich cultural heritage. Some must-visit destinations in Kashmir include Srinagar, known for its picturesque Dal Lake and Mughal Gardens; Gulmarg, a popular ski resort and meadow; Pahalgam, offering breathtaking valleys and the starting point for the Amarnath Yatra pilgrimage; Sonamarg, known as the "Meadow of Gold"; and the serene and pristine beauty of Leh and Ladakh.</p>
        <h3 data-aos="fade-up">Q2 :Is Kashmir safe for travelers?</h3>
        <p data-aos="fade-up"> Kashmir is a beautiful and welcoming destination for travelers. While the region has experienced occasional unrest in the past, it is generally considered safe for tourists. We closely monitor the situation in Kashmir and follow the guidance and recommendations of local authorities and travel advisories. Your safety and security are our top priority, and we take all necessary precautions to ensure a safe and enjoyable travel experience.</p>
        <h3 data-aos="fade-up">Q3 :What is the best time to visit Kashmir?</h3>
        <p data-aos="fade-up">The best time to visit Kashmir depends on your preferences and the experiences you seek. Spring (March to May) and autumn (September to November) are considered ideal for pleasant weather, blooming gardens, and clear skies. Summer (June to August) is popular for outdoor activities and enjoying the cool climate. Winter (December to February) is perfect for experiencing snowfall, skiing, and exploring frozen lakes. Each season offers a unique charm, and we can help you plan your trip according to your preferred time of visit.</p>
        <h3 data-aos="fade-up">Q4 : What types of activities can I experience in Kashmir?</h3>
        <p data-aos="fade-up">Kashmir offers a wide range of activities for every type of traveler. You can enjoy shikara rides on Dal Lake, go trekking in the stunning Himalayan ranges, experience houseboat stays, visit ancient temples and mosques, explore vibrant local markets, indulge in adventure sports like skiing and paragliding, and immerse yourself in the rich cultural heritage of the region. Our itineraries are designed to include a variety of activities that cater to different interests.</p>
        <h3 data-aos="fade-up">Q5: What is the currency and payment options in Kashmir?</h3>
        <p data-aos="fade-up">The official currency in Kashmir is the Indian Rupee (INR). Most businesses, hotels, and restaurants accept major credit cards, and ATMs are available in cities and tourist areas. It's advisable to carry some cash for small transactions and in case of emergencies. We recommend informing your bank about your travel plans to avoid any issues with card transactions and withdrawals.</p>
        <h3 data-aos="fade-up">Q6 :What is the transportation like in Kashmir?</h3>
        <p data-aos="fade-up">Kashmir has a well-developed transportation system. For longer distances, you can travel by road in comfortable buses or private cars. Local transportation within cities and towns is readily available in the form of taxis, auto-rickshaws, and shared minivans. Additionally, hiring a private vehicle with a driver is a popular option for exploring the region at your own pace. We can assist you in arranging suitable transportation options based on your preferences and the nature of your trip.</p>
        <h3 data-aos="fade-up">Q7 :Are English-speaking guides available during tours in Kashmir?</h3>
        <p data-aos="fade-up"> Yes, we provide knowledgeable and professional English-speaking guides who are well-versed in the history, culture, and attractions of Kashmir. Our guides are passionate about sharing their insights and ensuring you have a comprehensive understanding of the places you visit. If you require guides proficient in other languages, please inform us in advance, and we will do our best to accommodate your request.</p>
        <h3 data-aos="fade-up">Q8 :What type of accommodations can I expect in Kashmir?</h3>
        <p data-aos="fade-up"> Kashmir offers a wide range of accommodations to suit different budgets and preferences. You can choose from luxurious hotels and resorts that provide world-class amenities and breathtaking views, boutique guesthouses and heritage properties that offer a blend of comfort and local charm, or budget-friendly options such as guesthouses and homestays that provide a more authentic experience. We can recommend and arrange accommodations based on your requirements.</p>
        <h3 data-aos="fade-up">Q9 :Can you assist with arranging permits for restricted areas in Kashmir?</h3>
        <p data-aos="fade-up">Yes, if you plan to visit restricted areas such as certain parts of Ladakh or the famous Amarnath Cave, we can assist you in obtaining the necessary permits. These permits are required to ensure the safety and security of travelers and to regulate access to certain sensitive areas. Our team will guide you through the permit application process and provide all the information you need to comply with the requirements.</p>
        <h3 data-aos="fade-up">Q10 :What should I pack for my trip to Kashmir?</h3>
        <p data-aos="fade-up">When packing for Kashmir, consider the season and activities you plan to engage in. In general, it is advisable to pack comfortable clothing, including light and breathable fabrics for summers and warmer clothing layers for winters. Don't forget essentials like comfortable walking shoes, sunscreen, hats, sunglasses, and insect repellent. If you are visiting during winter or planning to go to higher altitudes, pack warm clothing, gloves, and a good pair of boots. It's also a good idea to carry a small backpack for day trips and excursions.</p>
      </div>
    </div>
  )
}

export default Faq
import React ,{ useEffect }from 'react'
import "../Css/hero.css"
import "../Css/Heron.css"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import AOS from 'aos'
import 'aos/dist/aos.css'
const Hero = () => {
  useEffect(()=>{
    AOS.init({duration:2000})
  },[])
  return (
    <div>
    <div data-aos="zoom-in" className='container1'>
    </div > 
    <div data-aos="zoom-in" className='newhero'>

    <h1 data-aos="fade-up " className='hg' >Make Your Holidays in your Budget</h1> 
        <p data-aos="fade-up "className='pg'>The melting snow of the mountains fills the rivers, brooks, and streams meandering the valley, this is what makes Kashmir even more inviting.</p>
        
       
        <div className='button'>
        <a data-aos="fade-up"
     
     className='btn-grad' href='https://api.whatsapp.com/send?text=i saw this on Websites i want to know more about your business &phone=+919419123636'>Book Now</a>
        <a data-aos="fade-up"
       
      className='btn-grad' href='tel:+916005080532'> Call Now</a>
    </div>
    <h1 data-aos="fade-up" className='hk'> Book Your Tour Now</h1>
    <p data-aos="fade-up" className='pg'> Unlock the wonders of Kashmir with our expertly crafted itineraries. Immerse yourself in the breathtaking beauty, vibrant culture, and warm hospitality that await you. Don't miss out on this opportunity to create lifelong memories.</p>
    <div className='newcard'>
    <Card data-aos="fade-up" className='card44'>
      <Card.Img variant="top" src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1684920932/front-image-4-package-3_k7x2fu.png" />
      <Card.Body>
        <Card.Title>KASHMIR BUDGET TOUR PACKAGE ( 4 NIGHTS & 5 DAYS )</Card.Title>
        
        <Button variant="primary">Price On Call</Button>
      </Card.Body>
    </Card>
    
    
       <Card data-aos="fade-up" className='card44'>
      <Card.Img variant="top" src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1684923026/front-image-2-package-2_ubhxzf.png" />
      <Card.Body>
        <Card.Title>KASHMIR BUDGET TOUR PACKAGE ( 5 NIGHTS & 6 DAYS )</Card.Title>
       
        <Button variant="primary">Price On Call</Button>
      </Card.Body>
    </Card>
    
    
       <Card data-aos="fade-up" className='card44'>
      <Card.Img variant="top" src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1684923149/front-image-3-package_fmsezr.png" />
      <Card.Body>
        <Card.Title>KASHMIR SUMMER TOUR PACKAGE ( 6 NIGHTS & 7 DAYS )</Card.Title>
        
        <Button variant="primary">Price On Call</Button>
      </Card.Body>
    </Card>
    
    
       <Card data-aos="fade-up" className='card44'>
      <Card.Img variant="top" src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1684923221/front-image-2-package_gqhpey.png" />
      <Card.Body>
        <Card.Title>KASHMIR SUMMER TOUR PACKAGE ( 5 NIGHTS & 6 DAYS )</Card.Title>
       
        <Button variant="primary">Price On Call</Button>
      </Card.Body>
    </Card>
    <Card data-aos="fade-up" className='card44'>
      <Card.Img variant="top" src="https://res.cloudinary.com/ddkyeuhk8/image/upload/c_scale,h_600,w_590/v1685547401/ladakh-2818861_1280_ebiap9.jpg" />
      <Card.Body>
        <Card.Title>Ladakh Tour package ( 5 NIGHTS & 6 DAYS )</Card.Title>
       
        <Button variant="primary">Price On Call</Button>
      </Card.Body>
    </Card>
    </div>
    <div data-aos="fade-up" style={{padding:"20px"}}>
    <h1 data-aos="fade-up">Kashmir Destinations</h1>
   <p data-aos="fade-up"> <b>Gulmarg</b> was discovered in the 16th century by Sultan Yusuf Shah, who was inspired by the sights of verdant slopes decorated with wild flowers. Today, Gulmarg is a premier mountain resort that offers pristine beauty and tranquility in its surroundings.<br/>

   <b>Sonmarg – “Meadow of Gold”</b>   stands tall at a lofty altitude of 2,740 m above sea level. The region is famous for its picturesque natural beauty that involves vivid glaciers and serene lakes, with colourful flower-laden meadow amidst the majestic Himalayas. This town is the gateway to Ladakh and suitable for all types of trekking expeditions, during the pleasant summers.<br/>

   <b>Pahalgam – “The Valley of Shepherds” </b>  is artistically placed at the confluence of the streams flowing from Sheshnag Lake and the Lidder river. It is an erstwhile shepherd’s village with breathtaking views of the snow-capped mountain peaks, verdant landscapes and refreshing atmosphere for a picnic.<br/>

Patnitop is yet another jewel in this paradise state. With River Chenab flowing in its proximity, the region offers pristine views of the landscape.<br/>

So come enjoy the shikara ride, a hike in the mountains, a quaint vacation in the raw lap of Mother Nature, or perhaps shopping for the Angora wool, paper mache products and other exotic flavours of the state.</p><br/>
    </div>
    </div>
    </div>
  )
}

export default Hero
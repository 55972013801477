import { React, useEffect } from "react"

import "../Css/hero.css"
import AOS from 'aos'
import 'aos/dist/aos.css'

const Image1 = () => {
  useEffect(()=>{
    AOS.init({duration:2000})
  },[])
  return (
    <div className='first1'>
        <h1 className='textg' data-aos="fade-up"> Kashmir Highlights</h1>
        <p className='textg' data-aos="fade-up">We operate all over the world from deserts, canyons, mountains, beaches and beyond.</p>
        <div className='first2'>
        <div data-aos="fade-up" className='imgc'>

          <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685027059/shikara-ride_unjxqw.jpg' alt='ki'/>
          <h5>Shikara ride</h5>
        </div>
        <div data-aos="fade-up" className='imgc'>

<img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685027064/Houseboat-Stay_vxoxz7.jpg' alt='ki'/>
<h5>Houseboat Stay</h5>
</div>
<div data-aos="fade-up" className='imgc'>

<img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685027069/tulip-garden-2_ctpvow.jpg' alt='ki'/>
<h5>Tulip Garden</h5>
</div> <div data-aos="fade-up" className='imgc'>

<img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685027078/Apple_Orchard_vab2zl.jpg' alt='ki'/>
<h5>Apple Orchards</h5>
</div> <div data-aos="fade-up" className='imgc'>

<img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685027084/Pahalgam-Sightseeing_n0egkl.jpg' alt='ki'/>
<h5>Pahalgam SightSeeing</h5>
</div> <div data-aos="fade-up" className='imgc'>

<img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685027093/Sonamarg-Sightseeing_cm41dr.jpg' alt='ki'/>
<h5 data-aos="fade-up">Sonamarg SightSeeing</h5>
</div>
<div data-aos="fade-up" className='imgc'>

<img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/c_scale,h_300,w_300/v1685547403/ladakh-2300904_640_annwnz.jpg' alt='ki'/>
<h5 data-aos="fade-up">ladakh</h5>
</div>
<div className='textg'>
  <h1 data-aos="fade-up">Things to do</h1>
  <p data-aos="fade-up">1.Shikara Ride on Dal Lake is an enjoyable activity by tourists. <br/>

2.Sightseeing of Vegetable Floating gardens in Dal Lake is unique experience.<br/>

3.Trek to Shankracharya Hill is place for Nature lovers and Adventure Seekers.<br/>

4.Dachigam National Park is home for Adventure and nature lovers.<br/>

5.Visiting Sahlimar and Nishat Bagh are mesmerizing gardens and are premiere attractions of Srinagar City.<br/>

6.Lalchowk, Polo View, Boulevard Road are the Shopping Centers to buy all kinds of products especially Kashmiri Shawls, Paper Mashie, Souvenirs etc<br/>

7.Hazratbal Mosque, Jama Masjid, Hari Parbat, Pari Mahal are among prime attractions to visit in Srinagar.<br/>

8.Bird Lovers and ornithologist don’t forget to Visit Hokersar a home for migratory birds from Siberia and Central Asia.<br/>

9.Houseboats: It is an unavoidable fact that any tourist visit Srinagar City spends at least one night in Houseboat in Dal Lake and Nageen Lake. These houseboats are designed very beautifully with Cedar Wood and paintings of Kashmir Arts. Houseboats are categorized by Standard, Deluxe and Super Deluxe options with all rooms having Bathroom and Toilet Facilities along with sun deck area. It is really a worth accommodation and feeling of unique experience to stay in.<br/><br/>

Best tourist places – Srinagar, Pahalgam, Sonmarg, Patnitop, Gulmarg, Bhderwah Best Time to Visit-August-March Weather- Pleasant and cool with an average temperature hovering between 6 C and 25 C Activities- Shikara ride, hot air balloon, Fishing, River rafting, Zorbing, water rides, trekking Where to stay – Hotels that offers all basic amenities. You can select from budget hotels, homestays, cottages and Houseboats What to pack – woollen clothes (you may never know when the temperature drops), first-aid kit, dry snacks and toiletries.</p>
</div><br/>
        
        

        </div>
    </div>
  )
}

export default Image1
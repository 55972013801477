
import { NavLink } from "react-router-dom";
import AOS from 'aos'
import 'aos/dist/aos.css'
import "../Css/Footer.css"
import { BiHome ,BiPhoneCall,BiEnvelope } from 'react-icons/bi';
import { AiOutlineInstagram } from 'react-icons/ai';

import { useEffect ,React } from "react";
const Footer = () => {
  useEffect(()=>{
    AOS.init({duration:2000})
  },[])
  return (
    <div className='mainfooter' data-aos="fade-up">
      <div className='textsec'>
        <div>
        <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1684914640/logo11_ofcvve.png' alt=''/>
        </div>
        <div>
        <h5>
        Unlock the Magic of Kashmir with Shaelsan Tour & Travels: Your Gateway to Heavenly Adventures!
        </h5>
        </div>
      </div>
      <hr/>
      <div className='textsec'>
        <h3><b>USEFULL LINKS</b></h3>
        <div >
        <NavLink  to="/">
          <h4 className='tom'>Home</h4>
        </NavLink>
        <NavLink  to="/about">
          <h4 className='tom'>About</h4>
        </NavLink>
        <NavLink  to="/faq">
          <h4 className='tom'>FAQs</h4>
        </NavLink>
        <NavLink  to="/gelery">
          <h4 className='tom'>Gallery</h4>
        </NavLink>
        <NavLink  to="/book">
          <h4 className='tom'>Book Now</h4>
        </NavLink>
     
        
       
        </div>

      </div>
      <hr/><div className='textsec' style={{textAlign:"justify"}}>
      <h3><b>CONTACT</b></h3>
      <div className='cont'>
      <p1><BiHome/> Nowpora Khayam 190001 Srinagar JK,  </p1> <br/>
      <p1><BiPhoneCall/> +91 6005080532 </p1><br/>
      <p1><BiEnvelope/> ShaelSan8@gmail.com ,<br/> <BiEnvelope/>Local4vocal36@gmail.com</p1>
      </div>
        
        </div>
        <br/>
        
        <div className="a4a"><hr/> Copyright © 2023 SHAELSAN All Rights Reserved.
        
        <br/>  website designed By  <a href="https://instagram.com/itz.kishor?igshid=MzNlNGNkZWQ4Mg=="> <AiOutlineInstagram/> Itz.kishor </a> <br/>
                          <a href="https://theaverage.in/?fbclid=PAAab71eTR-L7N76_Xxr_LKzxPutYIlJ0m3O9-h6w3xv-c65GtR6jtnOrZ6iQ"> TheAverage.in</a>
        </div>
     </div>
  )
}

export default Footer
import React from 'react'

import Hero from './Hero'
import Image1 from './Image1'







const Home = () => {
  return (
    <div>
        
        <div>
        <Hero/>
        <Image1/>
        
        </div>
        <div>
          
          
        
        </div>
    </div>
  )
}

export default Home
import { React, useEffect } from "react"
import "../Css/wat.css"
import AOS from 'aos'
import 'aos/dist/aos.css'
const Wats = () => {
    useEffect(()=>{
        AOS.init({duration:2000})
      },[])
  return (
    <div>
         <div className='wat'>
      <a data-aos="fade-left" href='https://api.whatsapp.com/send?text=i saw this one Websites i want to Know more  &phone=+919419123636' >
        <img src='https://res.cloudinary.com/drjh3dcfh/image/upload/v1684748368/4-2-whatsapp-transparent_dkamgd.png' alt='lk'/>
        Chat with us
      </a>
      
       <a data-aos="fade-right" href='tel:+916005080532'> Call us<img src='https://res.cloudinary.com/drjh3dcfh/image/upload/v1684748471/Pngtree_call_icon_png_3621527_uunk6o.png' alt='lk'/></a>

    </div>
    </div>
  )
}

export default Wats
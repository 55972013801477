
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import "../Css/Nav.css"
import { NavLink} from "react-router-dom";
function Navb() {
  return (
    <Navbar className='Nav' bg="light" expand="lg" fixed='top'>
      <Container fluid>
      <NavLink to="/" >    <Navbar.Brand to="/" className='image'><img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1684914640/logo11_ofcvve.png' alt='h' /></Navbar.Brand></NavLink>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '400px',borderRadius:'10px' }}
            id='mm'
          >

          <Nav.Link href='/' >Home</Nav.Link> 
               <Nav.Link href='/about' > About</Nav.Link>
             <Nav.Link href='/gelery' > Gallery</Nav.Link>
             <Nav.Link href='/faq' > FAQs</Nav.Link>
             <Nav.Link href='/book' > Book Now</Nav.Link>


          </Nav>
          <Form className="d-flex">
          
           
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navb;